import { LuneClient } from '@lune-climate/lune'
import { ApiError } from '@lune-climate/lune/esm/core/ApiError'
import { EnqueueSnackbar, useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { Result } from 'ts-results-es'

import { luneClientDoNotUseThisDirectly } from 'endpoints/api'
import useLogout from 'hooks/useLogout'
import { SnackbarMessages } from 'SnackbarMessages'

type ErrorHandlingSettings = {
    // A wordy prefix to avoid collisions with anything the TS client uses.
    // This is needed for reliable type detection in createErrorHandlingApiMethod.
    __dashboardStatusCodesToNotHandle: number[]
}

/**
 * This type adds an extra error handling parameters to the end of a type of a Lune API
 * client method.
 */
type ErrorHandlingApiMethod<T extends (...args: any[]) => Promise<Result<unknown, ApiError>>> = (
    ...args: [...Parameters<T>, errorHandling?: ErrorHandlingSettings]
) => ReturnType<T>

/**
 * The type of modified by us, dashboard error handling-aware Lune API client.
 */
type ErrorHandlingLuneClient = {
    // We want to convert all "actual" API methods to the versions with the extra parameter.
    //
    // Excluding setAccount as it's a special method that doesn't correspond to API calls
    // and there's no error handling, promises or errors involved.
    [k in Exclude<keyof LuneClient, 'setAccount'>]: ErrorHandlingApiMethod<LuneClient[k]>
} & Pick<typeof luneClientDoNotUseThisDirectly, 'setAccount'>

/**
 * Takes any method of `LuneClient` and returns an error handling-aware proxy for it. The
 * proxy takes an extra parameter (at the end of the parameter list) through which error
 * handling can be controlled.
 */
function createErrorHandlingApiMethod<
    // prettier and ESLint disagree
    // eslint-disable-next-line space-before-function-paren
    T extends (...args: any[]) => Promise<Result<unknown, ApiError>>,
>(
    method: T,
    snackbar: EnqueueSnackbar,
    logout: (options?: { path?: string }) => void,
): ErrorHandlingApiMethod<T> {
    function defaultErrorHandler(error: ApiError): ApiError {
        // logout the user if any endpoint return a 401
        if ('statusCode' in error && error.statusCode === 401) {
            snackbar(SnackbarMessages.ACCOUNT_UNAUTHENTICATED)
            logout()
            return error
        }

        const message =
            'statusCode' in error && error.statusCode === 429
                ? SnackbarMessages.RATE_LIMIT_ERROR
                : SnackbarMessages.GENERIC_ERROR
        snackbar(message)
        return error
    }

    return (...args) => {
        let statusCodesToNotHandle: number[] = []
        if (args.length > 0) {
            const lastArg = args.at(-1)
            if (
                typeof lastArg === 'object' &&
                lastArg !== null &&
                '__dashboardStatusCodesToNotHandle' in lastArg
            ) {
                statusCodesToNotHandle = lastArg.__dashboardStatusCodesToNotHandle
                args.pop()
            }
        }
        const result = method(...args)
        const withErrorHandler = result.then((result) =>
            result.mapErr((error) =>
                'statusCode' in error && statusCodesToNotHandle.includes(error.statusCode)
                    ? error
                    : defaultErrorHandler(error),
            ),
        )
        // SAFETY: TS has a limitation where it comes to understanding constructs like this,
        // where we basically
        //
        // * Take a function f of type T
        // * Claim we return ReturnType<T>
        // * Call f and try to return the value it returns
        //
        // The compiler doesn't currently (TypeScript 5.4.5, 2024-06-13) recognize that this
        // is all good, hence the type assertion.
        //
        // https://github.com/microsoft/TypeScript/issues/24277
        return withErrorHandler as ReturnType<T>
    }
}

/**
 * An internal function, exported only for testing.
 *
 * What we do here is based on the LuneClient instance provided we create
 * a proxy (or a wrapper, if you will) to the original client.
 */
export function createErrorHandlingLuneClient(
    client: LuneClient,
    snackbar: EnqueueSnackbar,
    logout: (options?: { path?: string }) => void,
): ErrorHandlingLuneClient {
    // The way the lune-ts LuneClient is constructed the properties/methods are
    // inherited and seem non-enumerable so it's something that for (const name in client)
    // and Objects.keys(client) won't handle.
    //
    // Hence the Object.getOwnPropertyNames use and passing the LuneClient prototype there,
    // not the actual instance we received.
    //
    // Some very interesting details and tables if one's interested in knowing more:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Enumerability_and_ownership_of_properties
    //
    // The property list contains the class' constructor which we don't want or need.
    const propertiesToCopy = Object.getOwnPropertyNames(LuneClient.prototype).filter(
        (name) => name !== 'constructor',
    )

    const result: Record<string, unknown> = {}
    for (const name of propertiesToCopy) {
        let value = (client as any)[name]
        if (typeof value === 'function') {
            // This is crucial – whatever methods we extracted from the client instance
            // through the [name] access above need to have the correct "this" value when
            // we call them in the wrapper code.
            //
            // This call ensures that "this" will refer to the original client object.
            value = value.bind(client)
        }
        result[name] =
            typeof value === 'function' && name !== 'setAccount'
                ? createErrorHandlingApiMethod(value, snackbar, logout)
                : value
    }

    // SAFETY: There's just no reasonable way to make the compiler understand that what
    // we produce above matches the expected type.
    return result as ErrorHandlingLuneClient
}

/**
 * Creates a version of `LuneClient` with some error attached to the API response
 * errors by default.
 *
 * You can adjust the error handling by passing a value returned by `dontHandle`
 * as an extra parameter when calling `LuneClient` methods after all the regular
 * `LuneClient` parameters.
 *
 * The default error handling is only attached for side effects (user notifications),
 * it doesn't actually swallow errors. The values returned are still `Result` values
 * that can contain a success or a failure.
 *
 * The default error handler is not attached to the whole client but to individual
 * methods and can be overridden on a per-method-call basis.
 *
 * @example
 * ```
 * const client = useLuneClient()
 *
 * // Default error handling
 * const result1 = (await client.getAccount())
 *
 * // No default error handling for HTTP 409 responses. Pay close attention to the
 * // parameter list:
 * //
 * // 1. We pass undefined because `LuneClient.getAccount` takes one optional parameter
 * //    on its own.
 * // 2. The value returned by `dontHandle` is passed as an extra parameter
 * //    we added here, it will be handled transparently and won't be passed to the
 * //    original `LuneClient`.
 * const result2 = (await client.getAccount(undefined, dontHandle([404])))
 * ```
 */
export function useLuneClient(): ErrorHandlingLuneClient {
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { logout } = useLogout()

    // This local variable is only needed to silence ESLint, it'd complain otherwise:
    //
    // "React Hook useMemo has an unnecessary dependency: 'luneClient'. Either exclude
    // it or remove the dependency array. Outer scope values like 'luneClient' aren't
    // valid dependencies because mutating them doesn't re-render the component
    // react-hooks/exhaustive-deps".
    //
    // An artificial local variable makes ESLint happy and as far as I can tell the code
    // works as expected.
    //
    // luneClient will change very rarely anyway, only in situations like login.
    const localLuneClient = luneClientDoNotUseThisDirectly

    // Memoizing the result not because it's expensive but to prevent unnecessary
    // re-renders or, what's more important, unnecessary API calls upstream. Extra calls
    // can happen if the value returned from useLuneClient it used in a dependency array
    // for a piece of code calling API methods on the client.
    return useMemo(
        () => createErrorHandlingLuneClient(localLuneClient, snackbar, logout),
        [localLuneClient, snackbar],
    )
}

/**
 * Excludes selected status codes from the default error handling provided by the
 * `useLuneClient`-provided Lune API client.
 *
 * An empty `statusCode` array is a programming error.
 *
 * @example
 * ```
 * const client = useLuneClient()
 * const result = (await client.getAccount(undefined, dontHandle([404])))
 * ```
 */
export function dontHandle(statusCodes: number[]): ErrorHandlingSettings {
    if (statusCodes.length === 0) {
        throw new Error('To disable some status code handling you need to provide at least one')
    }
    return {
        __dashboardStatusCodesToNotHandle: statusCodes,
    }
}
