import { LoadingWrapper, NoResultsPlaceholder, Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import Chart, { ChartConfiguration } from 'chart.js/auto'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Range } from 'react-date-range'

import 'chartjs-adapter-moment'

import ChartContainer from './ChartContainer'
import { chartOptions, EmptyGraph } from './ChartsConfig'
import { chartAreaBorder, padLeftNumber, padLeftString } from './chartUtils'
import ChartLegend from './EmissionsChartLegend'

// Make sure that the data being consumed is all of the same type (data, dataColours, dataLabels and labelOrdering).
type ValidDatasetKey<T> = T extends string ? T : never
type EmissionChartProps<T> = React.PropsWithChildren<{
    title?: string
    data: ({ date: string } & { [key in ValidDatasetKey<T>]: number })[]
    dataColours: { [key in ValidDatasetKey<T>]: string }
    dataLabels: { [key in ValidDatasetKey<T>]: string }
    dateRange: Range
    loading: boolean
    showLegend: boolean
    labelOrdering: (a: ValidDatasetKey<T>, b: ValidDatasetKey<T>) => number
    skipZeroOnTooltip: boolean
    isEmpty?: boolean
    reverseTooltipOrder?: boolean
}>

export const EmissionsChart = <T,>({
    title,
    data,
    dataColours,
    dataLabels,
    dateRange,
    loading,
    showLegend,
    labelOrdering,
    skipZeroOnTooltip,
    isEmpty,
    reverseTooltipOrder,
}: EmissionChartProps<T>) => {
    const [graphLegend, setGraphLegend] = useState<{ color: string; label: string }[]>()
    const canvasEl = useRef<HTMLCanvasElement | null>(null)

    const chartData = useMemo(() => {
        const rangeDuration = moment.duration(moment(dateRange.endDate).diff(dateRange.startDate))
        const format =
            !dateRange.endDate || !dateRange.startDate || rangeDuration.asMonths() > 1
                ? 'MMM, YYYY'
                : 'DD MMM, YYYY'

        const datasets = []
        // Parse all data for each of the labels in the wanted order
        const orderedLabels = (Object.keys(dataLabels) as (keyof typeof dataLabels)[]).sort(
            labelOrdering,
        )
        setGraphLegend(
            orderedLabels.map((l) => {
                return { color: dataColours[l], label: dataLabels[l] }
            }),
        )
        // To avoid the barChart overlapping with the Y-axis label we sometimes add an extra datapoint.
        const shouldPadLeft: boolean = data.length >= 5
        for (const key of orderedLabels) {
            const typedKey = key
            const parsedData = data.map((d) => d[typedKey])
            datasets.push({
                data: shouldPadLeft ? padLeftNumber(parsedData) : parsedData,
                backgroundColor: dataColours[typedKey],
                barThickness: 12,
            })
        }
        const parsedData = data.map((d) => {
            return moment(d.date).format(format)
        })
        return {
            labels: shouldPadLeft ? padLeftString(parsedData) : parsedData,
            datasets,
        }
    }, [data, dateRange])

    useEffect(() => {
        const ctx = canvasEl.current?.getContext('2d')
        const maxStackedValue = Math.max(
            ...chartData.labels.map((_, labelIndex) => {
                return chartData.datasets.reduce(
                    (sum, dataset) => sum + dataset.data[labelIndex],
                    0,
                )
            }),
        )
        const orderedLabelTranslation = (Object.keys(dataLabels) as (keyof typeof dataLabels)[])
            .sort(labelOrdering)
            .map((l) => dataLabels[l])
        const config = {
            type: 'bar',
            data: chartData,
            options: chartOptions(
                'tCO₂e',
                true,
                maxStackedValue,
                skipZeroOnTooltip,
                orderedLabelTranslation,
                reverseTooltipOrder ?? false,
            ) as any,
            plugins: [chartAreaBorder],
        }
        if (ctx) {
            const barChart = new Chart(ctx, config as ChartConfiguration)

            return () => {
                barChart.destroy()
            }
        }
    }, [chartData])

    if (isEmpty) {
        return (
            <>
                {title && (
                    <Text variant={'h6'} sx={{ marginBottom: '20px' }}>
                        {title}
                    </Text>
                )}
                {EmptyGraph()}
            </>
        )
    }

    return (
        <>
            {data.length ? (
                <Box>
                    {showLegend && graphLegend && <ChartLegend data={graphLegend} />}
                    <LoadingWrapper loading={loading} sx={{ height: '400px', width: '100%' }}>
                        {title && (
                            <Text variant={'h6'} sx={{ marginBottom: '20px' }}>
                                {title}
                            </Text>
                        )}
                        <ChartContainer>
                            <Box sx={{ height: '400px' }} id={'stacked-bar-chart'}>
                                <canvas id="emissionsChart" ref={canvasEl} height="100" />
                            </Box>
                        </ChartContainer>
                    </LoadingWrapper>
                </Box>
            ) : (
                <Box sx={{ height: `450px` }}>
                    <NoResultsPlaceholder
                        message={
                            <Text
                                variant={`h6`}
                                sx={{
                                    textAlign: 'center',
                                    maxWidth: '250px',
                                }}
                            >
                                You don&apos;t have any emissions calculations for the selected time
                                period
                            </Text>
                        }
                    />
                </Box>
            )}
        </>
    )
}

export default EmissionsChart
