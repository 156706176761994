import { EmissionFactorLogicalUnit, EmissionFactorRegions } from '@lune-climate/lune'
import { Button, MainLayoutContainer, NoResultsPlaceholder, Text } from '@lune-fe/lune-ui-lib'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { listEmissionFactors } from 'endpoints/dapi'
import { asSuccessResponseWithMeta } from 'endpoints/util'
import { useLuneClient } from 'hooks/useLuneClient'
import useUpdateEffect from 'hooks/useUpdateEffect'
import { EmissionFactor, PaginatedEmissionFactors } from 'models/openDapi'
import { IPaginationQueryParams } from 'models/pagination'
import { SnackbarMessages } from 'SnackbarMessages'
import EmissionFactorsFiltersBox, {
    NAME_INPUT_ID,
} from 'views/EmissionFactors/EmissionFactorsFiltersBox'
import EmissionFactorsList from 'views/EmissionFactors/EmissionFactorsList'

export type GetEmissionFactorsRequest = Parameters<typeof listEmissionFactors>[0]
export type GetEmissionFactorsFilters = Omit<
    GetEmissionFactorsRequest,
    keyof IPaginationQueryParams
>

const BrowseEmissionFactors: FC<{}> = () => {
    const PAGINATION_LIMIT = 10
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [loadingFilters, setLoadingFilters] = useState(true)
    const [paginatedEmissionFactors, setPaginatedEmissionFactors] = useState<
        PaginatedEmissionFactors & { lastId?: string }
    >()

    const [availableRegions, setAvailableRegions] = useState<EmissionFactorRegions>([])
    const [filterValues, setFilterValues] = useState<GetEmissionFactorsRequest>({})
    const luneClient = useLuneClient()

    const [, setSearchParams] = useSearchParams()

    const loadEmissionFactors = useCallback(
        async (filterValues: GetEmissionFactorsRequest) => {
            try {
                const paginatedResult = await listEmissionFactors({
                    limit: PAGINATION_LIMIT,
                    after: filterValues.after,
                    name: filterValues.name,
                    source: filterValues.source,
                    publicationYear: filterValues.publicationYear,
                    region: filterValues.region,
                    unit: filterValues.unit,
                    category: filterValues.category,
                })

                setPaginatedEmissionFactors((prevValue) => ({
                    hasMore: paginatedResult.hasMore,
                    nextCursor: paginatedResult.nextCursor,
                    data: filterValues.after
                        ? [...(prevValue ? prevValue.data : []), ...paginatedResult.data]
                        : paginatedResult.data,
                }))
                setLoading(false)
            } catch {
                setPaginatedEmissionFactors({
                    hasMore: false,
                    nextCursor: null,
                    data: [],
                })
                snackbar(SnackbarMessages.GENERIC_ERROR)
                setLoading(false)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterValues, snackbar],
    )

    const addFiltersToUrl = () => {
        const name = filterValues.name ? `name=${filterValues.name}` : undefined
        const source =
            filterValues.source && filterValues.source.length !== 0
                ? filterValues.source.map((s) => `source=${s}`).join('&')
                : undefined
        const region =
            filterValues.region && filterValues.region.length !== 0
                ? filterValues.region.map((s) => `region=${s}`).join('&')
                : undefined
        const year =
            filterValues.publicationYear && filterValues.publicationYear.length !== 0
                ? filterValues.publicationYear.map((s) => `year=${s}`).join('&')
                : undefined
        const category =
            filterValues.category && filterValues.category.length !== 0
                ? filterValues.category.map((s) => `category=${s}`).join('&')
                : undefined
        const unit = filterValues.unit ? `unit=${filterValues.unit}` : undefined
        const filters = [name, source, region, year, unit, category].filter((e) => e !== undefined)
        setSearchParams(filters.length !== 0 ? `${filters.join('&')}` : '')
    }

    useUpdateEffect(() => {
        addFiltersToUrl()
        loadEmissionFactors(filterValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, loadEmissionFactors])

    const fetchRegions = async () => {
        try {
            const { notAnObject: fetchedRegions } = asSuccessResponseWithMeta(
                (await luneClient.listAllEmissionFactorRegions()).unwrap(),
            )
            setAvailableRegions(fetchedRegions)
        } catch {
            snackbar(SnackbarMessages.GENERIC_ERROR)
        }
        setLoadingFilters(false)
    }

    useEffect(() => {
        fetchRegions()

        const params = new URLSearchParams(window.location.search)
        const name = params.get('name')
        const source = params.getAll('source')
        const region = params.getAll('region')
        const year = params.getAll('year')
        const unit = params.get('unit')
        const category = params.getAll('category')

        if (params.size === 0) {
            setLoadingFilters(false)
            loadEmissionFactors(filterValues)
            return
        }

        setFilterValues({
            ...filterValues,
            name: name ?? undefined,
            source: source as EmissionFactor['source'][],
            region,
            publicationYear: year.map((y) => Number(y)),
            unit: unit as EmissionFactorLogicalUnit | undefined,
            category,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onLoadMoreButtonClick = () => {
        const page = paginatedEmissionFactors?.nextCursor
        loadEmissionFactors({ ...filterValues, after: page ?? undefined })
    }

    return (
        <MainLayoutContainer
            maxWidth={'xl'}
            // Override the default padding of MainLayoutContainer
            sx={{
                my: 9,
                px: {
                    xs: 3,
                    sm: 3,
                    md: 3,
                    lg: 3,
                    xl: 3,
                },
            }}
            headerComponent={
                <Box>
                    <Button
                        data-testid={`close-button`}
                        variant="text"
                        iconButton
                        leftIcon={<CloseIcon />}
                        sx={{
                            ml: -1.5,
                            mb: 4,
                        }}
                        onClick={() => navigate('/calculate-emissions')}
                    />
                    <Box>
                        <Text
                            data-testid={`emission-factors-layout-title`}
                            variant={'h4'}
                            sx={{ mb: 9 }}
                        >
                            Browse emission factors
                        </Text>
                        {!loadingFilters && (
                            <EmissionFactorsFiltersBox
                                filterValues={filterValues}
                                setFilterValues={setFilterValues}
                                availableRegions={availableRegions}
                            />
                        )}
                    </Box>
                </Box>
            }
        >
            <>
                {!paginatedEmissionFactors?.data.length && !loading ? (
                    <Box sx={{ height: '550px', width: '100%' }}>
                        <NoResultsPlaceholder
                            message={
                                <Text
                                    variant={`h6`}
                                    sx={{
                                        textAlign: 'center',
                                        maxWidth: '429px',
                                    }}
                                >
                                    <Text
                                        variant={`h6`}
                                        sx={{
                                            textAlign: 'center',
                                            maxWidth: '429px',
                                            mb: 2,
                                        }}
                                    >
                                        We couldn’t find any emission factors matching your criteria
                                    </Text>
                                    <Button
                                        sx={{ backgroundColor: `white` }}
                                        variant={'outlined'}
                                        onClick={() => {
                                            setFilterValues({})
                                            // Retrieve the Name Input from EmissionFactorsFiltersBox and set it to empty
                                            // This is required since the component state does not depend on filterValues
                                            // due to debouncing, making clearing the filters not change it's value
                                            const e: HTMLInputElement = document.getElementById(
                                                NAME_INPUT_ID,
                                            ) as HTMLInputElement
                                            e.value = ''
                                        }}
                                    >
                                        Clear all filters
                                    </Button>
                                </Text>
                            }
                        />
                    </Box>
                ) : (
                    <>
                        {paginatedEmissionFactors && (
                            <EmissionFactorsList
                                paginatedEmissionFactors={paginatedEmissionFactors.data}
                            />
                        )}
                        {paginatedEmissionFactors?.hasMore && (
                            <Button
                                variant={`outlined`}
                                leftIcon={<ArrowDownwardIcon />}
                                onClick={() => onLoadMoreButtonClick()}
                                sx={{ mt: 9 }}
                            >
                                Load more
                            </Button>
                        )}
                    </>
                )}
            </>
        </MainLayoutContainer>
    )
}

export default BrowseEmissionFactors
