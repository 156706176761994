import {
    SuccessResponse,
    SuccessResponseWithMeta,
} from '@lune-climate/lune/esm/core/SuccessResponse'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export const bootstrapAxiosClient = (baseURL: string) => {
    const client = axios.create({
        baseURL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    })

    // Transform request payload keys camelCase --> snake_case
    client.interceptors.request.use((req) => {
        const requestCopy = { ...req }

        if (requestCopy.params) {
            requestCopy.params = snakeCaseKeys(req.params, { deep: true })
        }

        if (requestCopy.data) {
            requestCopy.data = snakeCaseKeys(req.data, { deep: true })
        }

        return requestCopy
    })

    // Transform response data keys snake_case --> camelCase
    client.interceptors.response.use(
        (response) => ({ ...response, data: camelCaseKeys(response.data, { deep: true }) }),
        (error) => {
            // Transform data keys in case an API response exists even.
            const errorResponse =
                error.response && error.response.data
                    ? {
                          ...error.response,
                          data: camelCaseKeys(error.response.data, { deep: true }),
                      }
                    : error.response
            error.response = errorResponse
            return Promise.reject(error)
        },
    )

    return client
}

export const bootstrapAxiosClientNoInterceptors = (baseURL: string) =>
    axios.create({
        baseURL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    })

export function asSuccessResponseWithMeta<T>(
    payload: SuccessResponse<T>,
): SuccessResponseWithMeta<T> {
    if (typeof payload === 'object' && payload !== null && !('_meta' in payload)) {
        throw new Error('Unexpected response payload: does not contain _meta information')
    }
    return payload as SuccessResponseWithMeta<T>
}
