// Disable sorting lint rule for the following import because Prism should be imported first before importing rest of the files related to Prism
// eslint-disable-next-line
import Prism from 'prismjs'

import '../css/prism-lune.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.js'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
// import languages that you want to support
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-markdown'
import 'prismjs/components/prism-fortran'

import ContentCopyIcon from '@mui/icons-material/ContentCopyOutlined'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import type { SxProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { LuneTheme } from '../theme'
import Button from './Button'
import Dropdown, { DropdownItem } from './Dropdown'
import Tile from './Tile'

export interface ISnippetItemProps {
    // Used to copy specific content
    toCopy?: string
    language?: string
    lineNumbers?: boolean
    label?: string
    children: React.ReactNode
    // Used to copy whatever content is passed to the snippet
    copiableContent?: boolean
}

export const SnippetItem: FC<ISnippetItemProps> = ({ language, lineNumbers, children }) => {
    const [toRender, setToRender] = useState<React.ReactNode>()

    useEffect(() => {
        setToRender(children)
    }, [children, language])

    useEffect(() => {
        Prism.highlightAll()
    }, [language, toRender])

    return (
        <>
            {language ? (
                <pre
                    className={lineNumbers ? `line-numbers` : ``}
                    style={{
                        padding: '0px',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <code className={`language-${language}`}>{toRender}</code>
                </pre>
            ) : (
                toRender
            )}
        </>
    )
}

const ResizeButton = () => {
    return (
        <Box
            data-resizer="true"
            sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '24px',
                height: '24px',
                transform: 'rotate(45deg)',
                cursor: 'nwse-resize',
                zIndex: '9',

                '&:after': {
                    content: '""',
                    width: '1px',
                    height: '15px',
                    position: 'absolute',
                    margin: 'auto',
                    background: '#E0E0E0',
                    top: '6px',
                    left: '10px',
                },
                '&:before': {
                    content: '""',
                    width: '1px',
                    height: '9px',
                    position: 'absolute',
                    margin: 'auto',
                    background: '#E0E0E0',
                    top: '8px',
                    left: '16px',
                },
            }}
        />
    )
}

export interface ISnippetProps {
    header: string
    sx?: SxProps
    children: React.ReactElement<ISnippetItemProps> | React.ReactElement<ISnippetItemProps>[]
    onChange?: (i: number) => void
    resizableInitialHeight?: number
}

const Snippet: FC<ISnippetProps> = ({
    header,
    onChange,
    children,
    resizableInitialHeight,
    sx,
    ...rest
}) => {
    const { typography, palette, spacing } = LuneTheme
    const divRef = useRef<HTMLDivElement>(null)

    const numChildren = React.Children.count(children)
    const multiChild = numChildren > 1

    const [localMaxHeight, setLocalMaxHeight] = useState<number>()

    if (numChildren === 0) {
        throw new Error('At least one child is required')
    }

    // toArray returns a Array<Exclude<ReactNode, boolean | null | undefined>>
    const [selected, setSelected] = useState<React.ReactElement<ISnippetItemProps>>(
        React.Children.toArray(children)[0] as React.ReactElement<ISnippetItemProps>,
    )

    useEffect(() => {
        if (!resizableInitialHeight) {
            return
        }

        const div = divRef.current

        if (!div) {
            return
        }

        const onMouseMove = (e: MouseEvent) => {
            e.stopPropagation()
            e.preventDefault()

            const newHeight = e.clientY - div.getBoundingClientRect().top
            setLocalMaxHeight(newHeight)
        }

        const onMouseUp = (e: MouseEvent) => {
            e.stopPropagation()
            e.preventDefault()

            window.removeEventListener('mousemove', onMouseMove)
            window.removeEventListener('mouseup', onMouseUp)
        }

        const onMouseDown = (e: MouseEvent) => {
            const target = e.target as HTMLElement
            if (target.dataset.resizer) {
                e.stopPropagation()
                e.preventDefault()

                window.addEventListener('mousemove', onMouseMove)
                window.addEventListener('mouseup', onMouseUp)
            }
        }

        div.addEventListener('mousedown', onMouseDown)
        return () => {
            div.removeEventListener('mousedown', onMouseDown)
        }
    }, [resizableInitialHeight])

    const StyledSnippet = useMemo(
        () =>
            styled(Stack)(
                LuneTheme.unstable_sx({
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                    overflow: 'hidden',
                    boxSizing: 'border-box',
                    '& pre': {
                        margin: '0px',
                        padding: '0px',
                    },
                    '& .MuiTypography-root[variant="body1"]': {
                        ...typography.body1,
                        color: palette.Grey900,
                    },
                    '& .MuiTypography-root[variant="body3"]': {
                        ...typography.body3,
                        color: palette.Grey900,
                    },
                    '& .MuiButton-root': {
                        // drowndown label
                        backgroundColor: palette.Grey100,
                        '& .MuiTypography-root': {
                            ...typography.body2,
                        },
                    },
                }),
            ),
        [],
    )

    const onDropdownChange = useCallback(
        (i: number) => {
            setSelected(
                React.Children.toArray(children)[i] as React.ReactElement<ISnippetItemProps>,
            )
            if (onChange) {
                onChange(i)
            }
        },
        [selected],
    )

    const { toCopy, label, children: selectedChildren, copiableContent } = selected.props

    const [copiable, setCopiable] = useState<string | undefined>()

    useEffect(() => {
        if (React.isValidElement(selectedChildren)) {
            setCopiable(
                typeof selectedChildren.props.children === 'string'
                    ? selectedChildren.props.children
                    : undefined,
            )
        } else if (typeof selectedChildren === 'string') {
            setCopiable(selectedChildren)
        } else {
            setCopiable(undefined)
        }
    }, [selectedChildren])

    return (
        <StyledSnippet
            ref={divRef}
            sx={{
                borderRadius: '16px',
                border: `solid 1px ${palette.Grey300}`,
                backgroundColor: palette.Grey50,
                position: 'relative',
                overflow: 'hidden',
                minHeight: '150px',
                height: localMaxHeight ? `${localMaxHeight}px` : 'max-content',
                ...sx,
            }}
            {...rest}
        >
            <Tile
                sx={{
                    backgroundColor: palette.Grey100,
                    minHeight: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                }}
                rightSide={
                    multiChild ? (
                        <Dropdown
                            buttonLabel={label ?? ''}
                            compact
                            menuAutoWidth
                            sx={{ minWidth: 'unset' }}
                        >
                            {React.Children.map(children, ({ props: { label } }, i: number) => (
                                <DropdownItem key={i} onClick={() => onDropdownChange(i)}>
                                    {label ?? ''}
                                </DropdownItem>
                            ))}
                        </Dropdown>
                    ) : undefined
                }
                button={
                    (copiable && copiableContent) || toCopy ? (
                        <CopyToClipboard text={copiable ?? toCopy ?? ''}>
                            <Button
                                variant="text"
                                iconButton
                                leftIcon={
                                    <ContentCopyIcon sx={{ width: '20px', height: '20px' }} />
                                }
                            />
                        </CopyToClipboard>
                    ) : undefined
                }
            >
                <Typography
                    variant="button"
                    color="Grey900"
                    sx={{
                        ...typography.button,
                        color: palette.Grey900,
                        marginLeft: spacing(1),
                    }}
                >
                    {header}
                </Typography>
            </Tile>
            <Box
                sx={{
                    overflow: 'auto',
                    padding: '16px',
                    display: 'flex',
                    height: '100%',
                    backgroundColor: palette.Grey50,
                    '&:first-child': {
                        margin: '0px',
                    },
                    fontSize: '13px',
                    fontWeight: '400',
                }}
            >
                {selected}
            </Box>
            {resizableInitialHeight && <ResizeButton />}
        </StyledSnippet>
    )
}

export default Snippet
