import { LuneTheme, NoResultsPlaceholder, Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { Chart as ChartJS } from 'chart.js/auto'

export interface Dataset {
    date: string
    quantity: string
    value: string
}

const { palette, typography } = LuneTheme

const getOrCreateTooltip = (chart: ChartJS) => {
    if (chart.canvas.parentNode !== null) {
        let tooltipEl = chart.canvas.parentNode.querySelector('div')
        if (!tooltipEl) {
            tooltipEl = document.createElement('div')
            tooltipEl.style.background = 'rgba(0, 0, 0)'
            tooltipEl.style.borderRadius = '4px'
            tooltipEl.style.color = 'white'
            tooltipEl.style.opacity = '1'
            tooltipEl.style.pointerEvents = 'none'
            tooltipEl.style.position = 'absolute'
            tooltipEl.style.transform = 'translate(-50%, 0)'
            tooltipEl.style.transition = 'all .1s ease'
            tooltipEl.style.fontSize = '12px'
            tooltipEl.style.marginTop = '-15px'
            tooltipEl.style.transform = 'translate(-50%, -100%)'
            tooltipEl.style.fontFamily = typography.fontFamily as any
            tooltipEl.style.minWidth = '100px'

            const caret = document.createElement('span')
            caret.style.width = '8px'
            caret.style.height = '8px'
            caret.style.backgroundColor = 'black'
            caret.style.position = 'absolute'
            caret.style.bottom = '-4px'
            caret.style.left = '0'
            caret.style.right = '0'
            caret.style.margin = 'auto'
            caret.style.zIndex = '-1'
            caret.style.transform = 'rotate(45deg)'

            const dot = document.createElement('span')
            dot.style.width = '8px'
            dot.style.height = '8px'
            dot.style.backgroundColor = 'black'
            dot.style.position = 'absolute'
            dot.style.bottom = '-18px'
            dot.style.left = '0'
            dot.style.right = '0'
            dot.style.margin = 'auto'
            dot.style.borderRadius = '100%'

            const table = document.createElement('table')
            table.style.margin = '0'

            tooltipEl.appendChild(table)
            tooltipEl.appendChild(caret)
            chart.canvas.parentNode.appendChild(tooltipEl)
        }
        return tooltipEl
    }
}

const createTooltipElement = (textString: string): HTMLTableRowElement => {
    const span = document.createElement('span')
    span.style.display = 'none' as const

    const tr = document.createElement('tr')
    tr.style.backgroundColor = 'black'
    tr.style.borderWidth = '0'
    tr.style.fontSize = '12px'

    const td = document.createElement('td')
    td.style.borderWidth = '0'
    tr.style.fontSize = '12px'
    tr.style.fontFamily = typography.fontFamily as string

    const text = document.createTextNode(textString)
    td.appendChild(span)
    td.appendChild(text)
    tr.appendChild(td)

    return tr
}

const externalTooltipHandler = (
    context: any,
    label: string,
    showTooltipLabels: boolean,
    skipZeroOnTooltip: boolean,
    orderedLabelTranslation: string[],
    reverseTooltipOrder: boolean,
) => {
    // Tooltip Element
    const { chart, tooltip } = context
    const tooltipEl = getOrCreateTooltip(chart)

    // Hide if no tooltip
    if (tooltipEl && tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0'
        return
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || []
        const bodyLinesRaw: any[] = tooltip.body.map((b: any) => b.lines)
        const bodyLines = reverseTooltipOrder ? bodyLinesRaw.reverse() : bodyLinesRaw

        titleLines.forEach((title: string) => {
            const tr = document.createElement('tr')
            tr.style.borderWidth = '0'
            tr.style.fontSize = '12px'
            tr.style.fontFamily = typography.fontFamily as string

            const th = document.createElement('th')
            th.style.borderWidth = '0'
            th.style.fontSize = '12px'
            const text = document.createTextNode(title)

            th.appendChild(text)
            tr.appendChild(th)
        })

        const tableBody = document.createElement('tbody')
        bodyLines.forEach((body: string, index: number) => {
            const labelIndex = reverseTooltipOrder ? bodyLines.length - index - 1 : index
            if (skipZeroOnTooltip && body[0] === '0') {
                return
            }

            const text = showTooltipLabels
                ? `${orderedLabelTranslation[labelIndex]}: ` + body + ` ${label}`
                : body + ` ${label}`
            tableBody.appendChild(createTooltipElement(text))
        })

        if (tableBody.children.length === 0) {
            tableBody.appendChild(createTooltipElement('No emissions'))
        }

        const tableRoot = tooltipEl?.querySelector('table')

        // Remove old children
        while (tableRoot?.firstChild) {
            tableRoot.firstChild.remove()
        }
        // Add new children
        tableRoot?.appendChild(tableBody)
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

    // Display, position, and set styles for font
    if (tooltipEl) {
        tooltipEl.style.opacity = '1'
        tooltipEl.style.left = positionX + tooltip.caretX + 'px'
        tooltipEl.style.top = positionY + tooltip.caretY + 'px'
        tooltipEl.style.font = typography.fontFamily as any
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
    }
}

// Chart options
export const chartOptions = (
    label: string,
    showTooltipLabels: boolean,
    maxDatapointValue: number,
    skipZeroOnTooltip: boolean,
    orderedLabelTranslation: string[],
    reverseTooltipOrder: boolean,
) => {
    return {
        maintainAspectRatio: false,
        elements: {
            point: {
                pointBorderColor: 'white',
                pointBorderWidth: '2',
                radius: 4,
            },
        },
        layout: {
            autoPadding: false,
            padding: {
                left: -20,
            },
        },
        scales: {
            y: {
                stacked: true,
                grid: {
                    tickLength: 0,
                    drawBorder: false,
                    color: ['#e9e9e9'],
                    tickBorderDashOffset: 0,
                },
                suggestedMax: maxDatapointValue * 1.2,
                ticks: {
                    mirror: true,
                    z: 9 as const,
                    padding: 10 as const,
                    showLabelBackdrop: true,
                    backdropColor: palette.White,
                    backdropPadding: 4,
                    textStrokeWidth: 10,
                    color: palette.Grey900,
                    font: {
                        family: typography.fontFamily,
                    },
                    callback: (value: any, index: any, ticks: any) => {
                        if (index === 0 || index === ticks.length - 1) {
                            return null
                        } else if (index === ticks.length - 2) {
                            return value + ` ${label}`
                        } else {
                            return value
                        }
                    },
                },
            },
            x: {
                stacked: true,
                ticks: {
                    beginAtZero: true,
                    font: {
                        family: typography.fontFamily,
                    },
                    color: palette.Grey900,
                },
                grid: {
                    tickLength: 10,
                    tickColor: palette.White,
                    drawBorder: false,
                    color: ['#e9e9e9'],
                },
            },
        },
        plugins: {
            paddingBelowLegends: '30px',
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                position: 'nearest' as const,
                external: (c: any) =>
                    externalTooltipHandler(
                        c,
                        label,
                        showTooltipLabels,
                        skipZeroOnTooltip,
                        orderedLabelTranslation,
                        reverseTooltipOrder,
                    ),
                mode: 'index',
                intersect: false,
            },
            chartAreaBorder: {
                borderColor: '#e9e9e9',
                borderWidth: 1,
            },
        },
    }
}

export const EmptyGraph = () => {
    return (
        <Box sx={{ height: '414px', width: '100%' }}>
            <NoResultsPlaceholder
                message={
                    <Text variant={'h6'} sx={{ textAlign: 'center', maxWidth: '250px' }}>
                        No emissions for the selected time period
                    </Text>
                }
            ></NoResultsPlaceholder>
        </Box>
    )
}
