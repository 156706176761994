import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { FC, useEffect, useState } from 'react'

import { LuneTheme } from '../theme'

import Dropdown, { DropdownItem } from './Dropdown'
import ListItemLayout from './ListItemLayout'

const DownloadDropdown: FC<{
    label?: string
    downloadPropMap: Record<
        string,
        { downloadFn: () => void; icon: JSX.Element | undefined; title: string }
    >
}> = (props: {
    label?: string
    downloadPropMap: Record<
        string,
        { downloadFn: () => void; icon: JSX.Element | undefined; title: string }
    >
}) => {
    const { spacing } = LuneTheme
    const [downloadType, setDownloadType] = useState<string>()
    const { downloadPropMap, label } = props

    const renderDropdownItems = () => {
        return Object.entries(downloadPropMap).map(([key, value]) => {
            return (
                <DropdownItem
                    sx={{
                        padding: `0 ${spacing(3)}`,
                        height: '48px',
                        width: '280px',
                    }}
                    onClick={() => {
                        setDownloadType(key)
                    }}
                    key={value.title}
                >
                    <ListItemLayout image={value.icon} title={value.title} />
                </DropdownItem>
            )
        })
    }

    useEffect(() => {
        if (downloadType && downloadType in downloadPropMap) {
            downloadPropMap[downloadType].downloadFn()
        }
    }, [downloadType])

    return (
        <Dropdown
            buttonLabel={label ?? 'Download'}
            typographyVariant={'button'}
            leftIcon={<FileDownloadOutlinedIcon />}
            sx={{
                backgroundColor: 'transparent',
                height: '48px',
                width: 'fit-content',
                minWidth: '64px',
                padding: '0 12px',
                border: '2px solid !important',
                borderColor: `${LuneTheme.palette.Grey900}`,
                color: `${LuneTheme.palette.Grey900}`,
                borderRadius: '40px',
                boxShadow: 'none',
                outline: 'black',
                whiteSpace: 'nowrap',
            }}
            menuPadding={'16px 0 16px 0'}
        >
            {renderDropdownItems()}
        </Dropdown>
    )
}

export default DownloadDropdown
