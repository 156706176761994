import { MultiLegShippingEmissionEstimate } from '@lune-climate/lune'
import { SuccessResponseWithMeta } from '@lune-climate/lune/esm/core/SuccessResponse'
import { useQuery } from 'react-query'

import { asSuccessResponseWithMeta } from 'endpoints/util'
import { useLuneClient } from 'hooks/useLuneClient'
import { queryKeys } from 'queryKeys'

const useMultiLegShipping = (id: string | undefined) => {
    const luneClient = useLuneClient()

    const getMultilegShippingResults = async (
        luneClient: ReturnType<typeof useLuneClient>,
        id: string,
    ) => {
        const e = (await luneClient.getMultiLegShippingEstimate(id)).unwrap()
        return asSuccessResponseWithMeta(e)
    }

    const {
        isLoading: loadingEstimate,
        error,
        data: estimate,
        refetch,
    } = useQuery<SuccessResponseWithMeta<MultiLegShippingEmissionEstimate> | undefined>(
        [queryKeys.GET_MULTILEG_EMISSIONS_CALCULATION_RESULTS, id],
        () => (id ? getMultilegShippingResults(luneClient, id) : undefined),
        {
            enabled: !!id,
            retry: false,
        },
    )

    return {
        loadingEstimate,
        error,
        estimate,
        refetch,
    }
}

export default useMultiLegShipping
