import { BundlePercentage, BundleSelection, PaginatedBundles } from '@lune-climate/lune'
import { SuccessResponseWithMeta } from '@lune-climate/lune/esm/core/SuccessResponse'
import { useCallback } from 'react'

import { asSuccessResponseWithMeta } from 'endpoints/util'
import useAccounts from 'hooks/useAccounts'
import { useLuneClient } from 'hooks/useLuneClient'
import { CUSTOM_PORTFOLIO_ID } from 'views/Settings/BundlePicker/BundlePicker'

const useIndividualAccountBundles = () => {
    const { activeAccount } = useAccounts()
    const luneClient = useLuneClient()
    const activeId = activeAccount?.id

    const loadAllBundles = useCallback(
        async (id: string) => {
            const response = (
                await luneClient.listBundles({ limit: '100' }, { accountId: id })
            ).unwrap()
            const { data }: PaginatedBundles = response
            return data
        },
        [luneClient],
    )

    const loadBundles = useCallback(
        async (id: string) => {
            const { notAnObject: data }: SuccessResponseWithMeta<BundleSelection> =
                asSuccessResponseWithMeta(
                    (await luneClient.getBundleSelection({ accountId: id })).unwrap(),
                )
            return data.reduce(
                (
                    acc: Record<string, number | string>,
                    { bundleId, percentage }: BundlePercentage,
                ) => {
                    acc[bundleId] = percentage
                    return acc
                },
                {},
            )
        },
        [luneClient],
    )

    const updateCustomBundleSelection = useCallback(
        async (id: string, newSelection: Record<string, number | string>) => {
            if (!id || !activeId) {
                return undefined
            }

            const bundleSelectionRequest = Object.entries(newSelection).map(
                ([bundleId, percentage]) => ({
                    bundleId,
                    percentage,
                }),
            )
            await luneClient.updateBundleSelection({ bundleSelectionRequest }, { accountId: id })
        },
        [activeId, luneClient],
    )

    const updateBundlePortfolioSelection = useCallback(
        async (id: string, bundlePortfolioId?: string) => {
            if (!id || !activeId) {
                return undefined
            }

            const acc = (await luneClient.getAccount({ accountId: id })).unwrap()
            await luneClient.updateAccount(id, {
                name: acc.name,
                beneficiary: acc.beneficiary ?? undefined,
                bundlePortfolioId:
                    bundlePortfolioId === CUSTOM_PORTFOLIO_ID ? undefined : bundlePortfolioId,
            })
        },
        [activeId, luneClient],
    )

    return {
        loadBundles,
        updateCustomBundleSelection,
        updateBundlePortfolioSelection,
        loadAllBundles,
    }
}

export default useIndividualAccountBundles
