import {
    EmissionFactorWithGasEmissions,
    GasEmissions,
    ShippingRoute,
    ShippingSourceDestination,
} from '@lune-climate/lune'
import { FC } from 'react'

import MapWithRoute from '..//map/MapWithRoute'

import EmissionFactorExplanation from './EmissionFactorExplanation'
import ExplanationSteps from './ExplanationSteps'
import { EmissionsExplanationSectionProps, useExplanationSteps } from './useExplanationSteps'
import { calculateEmissionFactorAmount, isAirShippingMethod } from './utils'
import WtwBreakdownExplanation from './WtwBreakdownExplanation'

export const EmissionsExplanationSection: FC<EmissionsExplanationSectionProps> = (props) => {
    const {
        distance,
        adjustedDistance,
        distanceCalculationMethod,
        load,
        convertedLoad,
        emissionFactor,
        emissions,
        responseRoute,
        requestRoute,
        requestMethod,
        mapboxAccessToken,
        hideEmissionFactorUrl,
    } = props

    const steps = useExplanationSteps(props)
    const showMap =
        (distanceCalculationMethod === 'vessel_tracking' || isAirShippingMethod(requestMethod)) &&
        responseRoute

    const localEmissionFactorAmount = calculateEmissionFactorAmount(
        convertedLoad,
        emissionFactor,
        load,
        adjustedDistance,
        emissions,
    )

    /**
     * To be called in the context where `responseRoute` is defined. If it's defined we know
     * we have to have provided source and destination to the API so let's verify that and,
     * if it's the case, return a type-narrowed version of the `route`.
     *
     * Calling this function when `responseRoute` is null is a programming error and will
     * result in an exception.
     */
    function expectSourceDestinationRoute(route: ShippingRoute | null): ShippingSourceDestination {
        if (responseRoute === null) {
            throw new Error(`This function can only be called when responseRoute is non-null`)
        }
        if (route === null || !('source' in route)) {
            throw new Error(
                `We expect the request route to be defined and to have source and destination`,
            )
        }
        return route
    }

    return (
        <>
            <ExplanationSteps steps={steps} />
            <WtwBreakdownExplanation emissions={emissions} />
            <EmissionFactorExplanation
                {...(emissionFactor.gasEmissions === null
                    ? {
                          emissionFactor: emissionFactor as Omit<
                              EmissionFactorWithGasEmissions,
                              'gasEmissions'
                          > & { gasEmissions: null },
                          amount: localEmissionFactorAmount.toString(),
                      }
                    : {
                          emissionFactor: emissionFactor as Omit<
                              EmissionFactorWithGasEmissions,
                              'gasEmissions'
                          > & { gasEmissions: GasEmissions },
                          amount: null,
                      })}
                label={emissionFactor.name}
                href={hideEmissionFactorUrl ? undefined : `/emission-factors/${emissionFactor.id}`}
            />
            {showMap && mapboxAccessToken && (
                <MapWithRoute
                    // SAFETY: The environment variable may not be defined and then
                    // we'll just crash.
                    mapboxAccessToken={mapboxAccessToken}
                    source={expectSourceDestinationRoute(requestRoute).source}
                    destination={expectSourceDestinationRoute(requestRoute).destination}
                    route={[
                        responseRoute.source.coordinates,
                        ...responseRoute.legs.map((l) => l.location.coordinates),
                    ]}
                    // SAFETY: The types don't express this but we know that when we get a route
                    // in response then we also have to get distance. Hence the type assertion.
                    distance={distance!}
                />
            )}
        </>
    )
}

export default EmissionsExplanationSection
