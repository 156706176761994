import { useState } from 'react'
import { Result } from 'ts-results-es'

interface DownloadPdfProps {
    fetcher: () => Promise<Result<Blob, string>>
    filename: string
}

const usePdfDownloader = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const downloadPdf = async ({ fetcher, filename }: DownloadPdfProps) => {
        setIsLoading(true)
        setError(null)

        let blob: Blob | undefined
        try {
            const blobResult = await fetcher()
            if (blobResult.isErr()) {
                setError(blobResult.error)
                return
            }
            blob = blobResult.value
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to download PDF')
        } finally {
            setIsLoading(false)
        }

        // Create a blob URL
        const blobUrl = URL.createObjectURL(blob!)

        // Create a temporary link element
        const link = document.createElement('a')
        link.href = blobUrl
        link.target = '_blank'
        link.download = filename

        // Append to document, click, and cleanup
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        // Cleanup blob URL
        URL.revokeObjectURL(blobUrl)
    }

    return { downloadPdf, isLoading, error }
}

export default usePdfDownloader
