import { Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import HorizontalSegmentedBar from 'lune-ui-lib/src/components/HorizontalSegmentedBar'

import { EmissionsExplanationSectionProps } from './useExplanationSteps'

export const WtwBreakdownExplanation = ({
    emissions,
}: {
    emissions: EmissionsExplanationSectionProps['emissions']
}) => {
    return (
        <>
            {!!emissions.wtwDetails && (
                <Box>
                    <Text variant={'h6'} sx={{ mb: 4 }}>
                        Emissions breakdown
                    </Text>
                    <HorizontalSegmentedBar
                        segments={[
                            {
                                value: Number(emissions.wtwDetails.wtt.amount),
                                unit: emissions.wtwDetails.wtt.unit,
                                colour: '#0099F1',
                                order: 0,
                                label: 'Well-To-Tank',
                            },
                            {
                                value: Number(emissions.wtwDetails.ttw.amount),
                                unit: emissions.wtwDetails.ttw.unit,
                                colour: '#3BC9F0',
                                order: 1,
                                label: 'Tank-To-Wheel',
                            },
                            {
                                value: Number(emissions.wtwDetails.unknown.amount),
                                unit: emissions.wtwDetails.unknown.unit,
                                colour: '#BDBDBD',
                                order: 2,
                                label: 'unknown stage',
                            },
                        ]}
                        legendOptions={{ ontop: false, vertical: true }}
                    />
                </Box>
            )}
        </>
    )
}

export default WtwBreakdownExplanation
