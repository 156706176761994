import { Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FC } from 'react'

const RoundLegend: FC<{ color: string; label: string }> = ({ color, label }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 2,
            }}
        >
            <Box
                sx={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '100%',
                    backgroundColor: color,
                    mr: '12px',
                }}
            />
            <Text variant={'body3'}>{label}</Text>
        </Box>
    )
}

export const ChartLegend: FC<{ data: { color: string; label: string }[] }> = (legendItems) => {
    const items = legendItems.data.map(({ color, label }, index) => {
        return <RoundLegend color={color} label={label} key={index} />
    })
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                flexWrap: 'wrap',
                gap: 1,
            }}
        >
            {items}
        </Box>
    )
}

export default ChartLegend
