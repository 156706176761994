import { CalculationResults, flattenLegs, Leg } from '@lune-fe/lune-components-lib'
import { Stack } from '@mui/material'
import { FC, RefObject, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import useMultiLegShipping from 'hooks/useMultiLegShipping'
import { EstimateTypeEnum, EstimateTypeName, ResultsWrapper } from 'views/CalculateEmissions'
import MultiLegEstimate from 'views/CalculateEmissions/MultiLegLogistics/MultiLegEstimate'

const MultiLegLogisticsResults: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({ scrollRef }) => {
    const { id } = useParams<{ id: string }>()
    const { estimate, loadingEstimate } = useMultiLegShipping(id)

    const legs: Leg[] = useMemo(() => {
        if (!estimate?.legs) {
            return []
        }
        return flattenLegs(estimate)
    }, [estimate])

    return (
        <ResultsWrapper
            scrollRef={scrollRef}
            calculatedAmount={estimate?.mass.amount!}
            loading={loadingEstimate}
        >
            {estimate && (
                <>
                    <CalculationResults
                        title={
                            estimate.request.name ||
                            EstimateTypeName(EstimateTypeEnum.MULTI_LEG_SHIPPING)
                        }
                        amount={estimate.mass.amount}
                    />
                    <Stack direction={'column'} spacing={9} mt={9}>
                        <MultiLegEstimate
                            legs={legs}
                            load={estimate.request.shipment}
                            meta={estimate._meta}
                            source={estimate.source}
                        />
                    </Stack>
                </>
            )}
        </ResultsWrapper>
    )
}

export default MultiLegLogisticsResults
