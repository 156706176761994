import { MAIN_NAV_LOCATION_KEY } from 'appConstants'
import mixpanel from 'mixpanel-browser'
import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { logout as logoutFromBE } from 'endpoints/dapi'
import useToken from 'hooks/useToken'

export default function useLogout(): {
    logout: (options?: { path?: string }) => void
} {
    const queryClient = useQueryClient()
    const { setToken } = useToken()
    const navigate = useNavigate()

    const releaseResourcesAndNavigateAway = (options?: { path?: string }) => {
        queryClient.removeQueries()
        localStorage.removeItem(MAIN_NAV_LOCATION_KEY)
        setToken(null)
        mixpanel.track('Logout')
        if (options?.path) {
            navigate(options.path)
            return
        }
        navigate(0)
    }

    const logout = useCallback(
        (options?: { path?: string }) => {
            logoutFromBE()
                .then(() => {
                    releaseResourcesAndNavigateAway(options)
                })
                .catch(() => {
                    releaseResourcesAndNavigateAway(options)
                })
        },
        [navigate, queryClient, setToken],
    )

    return { logout }
}
