import { EmissionFactorWithGasEmissions, EstimateMassUnit, GasEmissions } from '@lune-climate/lune'
import { EmissionFactorExplanation } from '@lune-fe/lune-components-lib'
import {
    BackButton,
    ButtonGroup,
    LoadingWrapper,
    MainLayoutContainer,
    Text,
} from '@lune-fe/lune-ui-lib'
import { Stack } from '@mui/material'
import HorizontalSegmentedBar from 'lune-ui-lib/src/components/HorizontalSegmentedBar'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useEmissionFactorVersions from 'hooks/useEmisssionFactorVersions'
import useNavigatedWithinTheApp from 'hooks/useNavigatedWithinTheApp'

const EmissionFactorDetails = () => {
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()
    const { emissionFactorVersions } = useEmissionFactorVersions(id)
    // Tracks changing tab after the initial load.
    const [activeTabIndex, setActiveTabIndex] = useState<number | undefined>()

    const shouldShowTheBackButton = useNavigatedWithinTheApp()

    let contents: JSX.Element | undefined
    if (emissionFactorVersions) {
        // A 0-based index of the currently shown emission factor. If we haven't switched tabs
        // yet we default to the emission factor selected via the id.
        const effectiveIndex = activeTabIndex
            ? activeTabIndex - 1
            : emissionFactorVersions.identifiedIndex
        const activeFactor = emissionFactorVersions.versions[effectiveIndex]
        contents = (
            <MainLayoutContainer
                headerComponent={
                    <>
                        {shouldShowTheBackButton && (
                            <BackButton
                                sx={{ mb: 3, ml: -2 }}
                                onClick={() => {
                                    return navigate(-1)
                                }}
                            />
                        )}
                        <Text variant={'h4'}>{activeFactor.name}</Text>
                    </>
                }
            >
                <Stack spacing={9}>
                    <ButtonGroup
                        items={emissionFactorVersions.versions.map((factor, index) => {
                            return {
                                value: (index + 1).toString(),
                                label: `v ${factor.sourceVersion}`,
                            }
                        })}
                        onChange={(active) => {
                            setActiveTabIndex(Number(active))
                        }}
                        value={`${effectiveIndex + 1}`}
                    />
                    <Stack spacing={'8px'}>
                        <Text variant={'h6'}>Information</Text>
                        <EmissionFactorExplanation
                            emissionFactor={
                                activeFactor as Omit<
                                    EmissionFactorWithGasEmissions,
                                    'gasEmissions'
                                > & {
                                    gasEmissions: GasEmissions
                                }
                            }
                            amount={null}
                        />
                    </Stack>
                    {typeof activeFactor.gasEmissions === 'object' &&
                        activeFactor.gasEmissions.wtwBreakdown && (
                            <Stack spacing={'8px'}>
                                <Text variant={'h6'}>Emissions breakdown</Text>
                                <HorizontalSegmentedBar
                                    segments={[
                                        {
                                            value: Number(
                                                activeFactor.gasEmissions.wtwBreakdown.wttCo2E,
                                            ),
                                            unit: `${activeFactor.numeratorUnit}CO₂e / ${activeFactor.denominatorUnit}` as EstimateMassUnit,
                                            colour: '#0099F1',
                                            order: 0,
                                            label: 'Well-To-Tank',
                                        },
                                        {
                                            value: Number(
                                                activeFactor.gasEmissions.wtwBreakdown.ttwCo2E,
                                            ),
                                            unit: `${activeFactor.numeratorUnit}CO₂e / ${activeFactor.denominatorUnit}` as EstimateMassUnit,
                                            colour: '#3BC9F0',
                                            order: 1,
                                            label: 'Tank-To-Wheel',
                                        },
                                    ]}
                                    legendOptions={{ ontop: false, vertical: true }}
                                />
                            </Stack>
                        )}
                </Stack>
            </MainLayoutContainer>
        )
    }

    return (
        <LoadingWrapper loading={contents === undefined} sx={{ height: 1 }}>
            {contents}
        </LoadingWrapper>
    )
}

export default EmissionFactorDetails
