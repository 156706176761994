import { LabeledFormSection, LoadingWrapper, Select } from '@lune-fe/lune-ui-lib'
import Stack from '@mui/material/Stack'
import { FormApi } from 'final-form'
import { Dispatch, FC, memo, SetStateAction, useCallback } from 'react'
import { Field, Form } from 'react-final-form'

import { FormInput } from 'components/FormInput'
import useIsTestMode from 'hooks/useIsTestMode'

const required = (value: any) => (value ? undefined : 'This is a required field')

export enum AccountFormKeys {
    NAME = 'name',
    DEFAULT_ACCOUNT = 'defaultAccount',
}

interface FormModel {
    [AccountFormKeys.NAME]: string
    [AccountFormKeys.DEFAULT_ACCOUNT]: string
}

const CreateApiKeyForm: FC<{
    accountsOptions: any
    initialValues: FormModel
    onSubmit: (data: FormModel) => Promise<any>
    loading: boolean
    setFormApi: Dispatch<SetStateAction<FormApi<FormModel> | undefined>>
    setFormValue: Dispatch<SetStateAction<FormModel | undefined>>
}> = ({ accountsOptions, initialValues, onSubmit, loading, setFormApi, setFormValue }) => {
    const isTestMode = useIsTestMode()
    const FormSelect = useCallback(
        ({ input: { onChange, value }, meta }: any) => (
            <Select
                sx={{
                    width: '100%',
                }}
                error={meta.touched && meta.error}
                value={value}
                onChange={onChange}
                items={accountsOptions}
            />
        ),
        [accountsOptions],
    )

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ form, values }) => {
                setFormValue(values)
                setFormApi(form)
                return (
                    <Stack
                        direction="column"
                        sx={{
                            mb: 9,
                            width: '100%',
                        }}
                        spacing={{
                            xs: 9,
                        }}
                    >
                        <LabeledFormSection title={'Name'}>
                            <Field
                                name={AccountFormKeys.NAME}
                                placeholder={'Name'}
                                component={FormInput}
                                validate={required}
                            />
                        </LabeledFormSection>

                        <LoadingWrapper loading={loading}>
                            <LabeledFormSection
                                title={'Default account'}
                                subTile={
                                    <>
                                        You can override it with any {isTestMode ? 'test' : 'live'}{' '}
                                        account in your organisation.{' '}
                                        <a
                                            target="_blank"
                                            href="https://docs.lune.co/general-concepts/accounts"
                                            rel="noreferrer"
                                        >
                                            Learn more
                                        </a>
                                    </>
                                }
                            >
                                <Field
                                    name={AccountFormKeys.DEFAULT_ACCOUNT}
                                    component={FormSelect}
                                    validate={required}
                                />
                            </LabeledFormSection>
                        </LoadingWrapper>
                    </Stack>
                )
            }}
        />
    )
}

export default memo(CreateApiKeyForm, (prev, next) => {
    return JSON.stringify(prev.initialValues) === JSON.stringify(next.initialValues)
})
