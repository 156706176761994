import {
    CumulativeBundleAnalytics,
    SustainabilityPage,
    SustainabilityPageDescription,
    SustainabilityPageStatus,
    SustainabilityPageTitle,
} from '@lune-climate/lune'
import { ApiError } from '@lune-climate/lune/esm/core/ApiError'
import { SuccessResponse } from '@lune-climate/lune/esm/core/SuccessResponse'
import { Button, LoadingWrapper, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Result } from 'ts-results-es'

import FormChangesDetector from 'components/FormChangesDetector'
import { asSuccessResponseWithMeta } from 'endpoints/util'
import useAccounts from 'hooks/useAccounts'
import { useLuneClient } from 'hooks/useLuneClient'
import useUserState from 'hooks/useUserState'
import { SnackbarMessages } from 'SnackbarMessages'
import ActionButtons from 'views/SustainabilityPage/ActionButtons'
import Description from 'views/SustainabilityPage/Description'
import PublicURL, { toSlug } from 'views/SustainabilityPage/PublicUrl'
import Sections from 'views/SustainabilityPage/Sections'
import Title from 'views/SustainabilityPage/Tile'

export enum SustainabilityPageSection {
    BUNDLES_BREAKDOWN = 'bundles_breakdown',
    CERTIFICATES = 'certificates',
    UNSDG = 'unsdg',
}

export interface TotalPlacedAnalytics {
    totalPlacedOffsetValue: number
    totalPlacedOffsetQuantity: number
}

const defaultOptions: SustainabilityPage = {
    slug: '',
    title: SustainabilityPageTitle.BY_VOLUME,
    description: SustainabilityPageDescription.BY_EQUIVALENT,
    customDescription: null,
    sections: [
        SustainabilityPageSection.BUNDLES_BREAKDOWN,
        SustainabilityPageSection.UNSDG,
        SustainabilityPageSection.CERTIFICATES,
    ],
    status: SustainabilityPageStatus.ENABLED,
}

const SustainabilityPageSettings = () => {
    const { activeAccount } = useAccounts()
    const { userState } = useUserState()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const [loading, setLoading] = useState<boolean>(true)
    const [isOnEdit, setIsOnEdit] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const navigate = useNavigate()
    const luneClient = useLuneClient()

    const [analytics, setAnalytics] = useState<TotalPlacedAnalytics>({
        totalPlacedOffsetValue: 0,
        totalPlacedOffsetQuantity: 0,
    })

    const [initialOptions, setInitialOptions] = useState<SustainabilityPage>(defaultOptions)
    const [options, setOptions] = useState<SustainabilityPage>(defaultOptions)

    useEffect(() => {
        setError(undefined)
        if (activeAccount) {
            luneClient.getSustainabilityPage().then((result) => {
                if (result.isOk()) {
                    const settings = result.value
                    setOptions(settings)
                    setInitialOptions(settings)
                    setIsOnEdit(true)
                } else {
                    setIsOnEdit(false)
                    const accountName = activeAccount.name
                        .replaceAll(' ', '-')
                        .trim()
                        .toLocaleLowerCase()
                    setOptions(() => {
                        return {
                            ...defaultOptions,
                            slug: toSlug(accountName),
                        }
                    })
                    setInitialOptions(() => {
                        return {
                            ...defaultOptions,
                            slug: toSlug(accountName),
                        }
                    })
                }
            })
        }
    }, [activeAccount, luneClient])

    useEffect(() => {
        luneClient
            .getCumulativeAnalyticsPerBundle()
            .then((result: Result<SuccessResponse<CumulativeBundleAnalytics>, ApiError>) => {
                if (!result.isErr()) {
                    const { notAnObject: data } = asSuccessResponseWithMeta(result.value)
                    const quantity: number = data
                        .map((d) => d.timeseries[d.timeseries.length - 1].quantity)
                        .reduce((a, b) => Number(a) + Number(b), 0)
                    const value: number = data
                        .map((d) => d.timeseries[d.timeseries.length - 1].value)
                        .reduce((a, b) => Number(a) + Number(b), 0)
                    setAnalytics({
                        totalPlacedOffsetQuantity: quantity,
                        totalPlacedOffsetValue: value,
                    })
                    setLoading(false)
                } else {
                    snackbar(SnackbarMessages.LOAD_ANALYTICS_FAIL)
                }
            })
    }, [snackbar, userState, luneClient])

    return (
        <MainLayoutContainer
            headerComponent={
                <Box>
                    <Button
                        variant="text"
                        iconButton
                        leftIcon={<CloseIcon />}
                        sx={{
                            ml: -1.5,
                            mb: 4,
                        }}
                        onClick={() => navigate('/go-to-market')}
                    />
                    <Box
                        sx={{
                            mb: 6,
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Box
                            sx={{
                                mr: 3,
                            }}
                        >
                            <Text variant={'h4'} sx={{ mb: 1 }}>
                                Share your impact
                            </Text>
                            <Text variant="body3" sx={{ color: `Grey700`, display: 'block' }}>
                                Make your sustainability efforts visible to your customers,
                                including live stats, visuals and certificates to prove your
                                commitment to the climate.
                            </Text>
                        </Box>
                        <ActionButtons
                            isOnEdit={isOnEdit}
                            setInitialOptions={setInitialOptions}
                            setOptions={setOptions}
                            options={options}
                            setError={setError}
                            error={error}
                            setIsOnEdit={setIsOnEdit}
                        />
                    </Box>
                </Box>
            }
        >
            <LoadingWrapper loading={loading} sx={{ minHeight: '450px' }}>
                <FormChangesDetector initialValues={initialOptions} newValues={options}>
                    <Stack
                        direction="column"
                        sx={{
                            mb: 9,
                            width: '100%',
                        }}
                        spacing={{
                            xs: 9,
                        }}
                    >
                        <PublicURL
                            options={options}
                            setOptions={setOptions}
                            setError={setError}
                            error={error}
                        />
                        <Title options={options} setOptions={setOptions} analytics={analytics} />
                        <Description
                            options={options}
                            setOptions={setOptions}
                            analytics={analytics}
                        />
                        <Sections
                            initialSections={initialOptions.sections}
                            setOptions={setOptions}
                        />
                    </Stack>
                </FormChangesDetector>
            </LoadingWrapper>
        </MainLayoutContainer>
    )
}

export default SustainabilityPageSettings
