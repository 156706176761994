import { BundlePortfolio } from '@lune-climate/lune'
import { SuccessResponseWithMeta } from '@lune-climate/lune/esm/core/SuccessResponse'
import { useQuery } from 'react-query'

import { asSuccessResponseWithMeta } from 'endpoints/util'
import { useLuneClient } from 'hooks/useLuneClient'
import { queryKeys } from 'queryKeys'

const useBundlesPortfolios = () => {
    const luneClient = useLuneClient()
    const { isLoading, data } = useQuery<SuccessResponseWithMeta<BundlePortfolio[]>>(
        queryKeys.GET_BUNDLE_PORTFOLIOS,
        async () =>
            asSuccessResponseWithMeta<BundlePortfolio[]>(
                (await luneClient.listAllBundlePortfolios()).unwrap(),
            ),
    )

    const bundlesPortfolios = data?.notAnObject ?? []

    return {
        loading: isLoading,
        bundlesPortfolios,
    }
}

export default useBundlesPortfolios
