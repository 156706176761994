import { useIsMobile } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FC, ReactNode, RefObject, useEffect, useRef, useState } from 'react'

export const ChartContainer: FC<{ children: ReactNode }> = ({ children }) => {
    const container: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
    const [containerLeftSpacing, setContainerLeftSpacing] = useState<number>(0)
    const { isMobile } = useIsMobile()

    useEffect(() => {
        function handleResize() {
            const pl = container.current?.offsetLeft
            setContainerLeftSpacing(pl || 0)
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <Box ref={container}>
            <Box
                sx={{
                    ...(isMobile && {
                        marginLeft: `-${containerLeftSpacing}px !important`,
                        paddingLeft: `${containerLeftSpacing}px`,
                        overflowX: 'auto',
                        width: `calc(100% + ${containerLeftSpacing}px)`,
                    }),
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        minWidth: isMobile ? '900px' : 'auto',
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    )
}

export default ChartContainer
