import {
    AggregatedAnalyticsByProperty,
    CountTimeseries,
    ShippingEmissionTimeseries,
    SupportedUNSdg,
    WtwBreakdownTimeseries,
} from '@lune-climate/lune'
import { EmissionsSectionChartToggle } from '@lune-fe/lune-components-lib'
import { LoadingWrapper, MainLayoutContainer } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { Range } from 'react-date-range'

import FiltersBox from 'components/FiltersBox'
import FiltersWithQueryParamsWrapper from 'components/FiltersWithQueryParamsWrapper'
import {
    getAggregatedAnalyticsByProperty,
    getCumulativeAnalyticsPerBundle,
    getEmissionCalculationMetrics,
    getMetrics,
    getShippingEmissionTimeseries,
} from 'endpoints/dapi'
import useMixpanel from 'hooks/useMixpanel'
import useRecentlyUsedAccounts from 'hooks/useRecentlyUsedAccounts'
import { OrderType } from 'models/order'
import { SnackbarMessages } from 'SnackbarMessages'
import { EstimateTypeName } from 'views/CalculateEmissions'
import { BundleData } from 'views/Dashboard/Charts/OrdersChartConfig'
import EmissionCalculationAnalytics from 'views/Dashboard/EmissionCalculationAnalytics'
import OrdersAnalytics from 'views/Dashboard/OrdersAnalytics'
import OrdersBreakdownCharts from 'views/Dashboard/OrdersBreakdownCharts'
import UNSDGs from 'views/Dashboard/UNSDGs'

const DATE_FORMAT = 'YYYY-MM-DD'

const compareParameters = (prevProps: any, nextProps: any): boolean => {
    const { dateRange, selectedAccountIds } = prevProps
    const { dateRange: nextDateRange, selectedAccountIds: nextSelectedAccountIds } = nextProps
    return (
        JSON.stringify(dateRange) === JSON.stringify(nextDateRange) &&
        JSON.stringify(selectedAccountIds) === JSON.stringify(nextSelectedAccountIds)
    )
}

const OrderChartsWrappers = ({
    dateRange,
    data,
    breakdowns,
    unSDGNumbers,
}: {
    dateRange: Range
    data?: BundleData[]
    breakdowns?: AggregatedAnalyticsByProperty
    unSDGNumbers?: SupportedUNSdg
}) => {
    const [analyticsType, setAnalyticsType] = useState<OrderType>(OrderType.QUANTITY)

    return (
        <>
            <OrdersAnalytics
                orderChartData={data}
                setAnalyticsType={setAnalyticsType}
                dateRange={dateRange}
                analyticsType={analyticsType}
            />
            {!!data?.length && (
                <>
                    <OrdersBreakdownCharts analyticsType={analyticsType} breakdowns={breakdowns} />
                    <UNSDGs unSDGNumbers={unSDGNumbers} />
                </>
            )}
        </>
    )
}

const getStartEndDates = (dateRange: Range) => {
    const start = dateRange.startDate
        ? moment(dateRange.startDate).format(DATE_FORMAT)
        : dateRange.startDate
    const end = dateRange.endDate
        ? moment(dateRange.endDate).format(DATE_FORMAT)
        : dateRange.endDate
    return { start, end }
}

const getInterval = (dateRange: Range) => {
    const rangeDuration = moment.duration(moment(dateRange.endDate).diff(dateRange.startDate))
    return !dateRange.endDate || !dateRange.startDate || rangeDuration.asMonths() > 1
        ? 'month'
        : 'day'
}

const ChartsWrapper = ({
    selectedAccountIds,
    dateRange,
}: {
    selectedAccountIds: string[]
    dateRange: Range
}) => {
    const { enqueueSnackbar: snackbar } = useSnackbar()

    const [loading, setLoading] = useState<boolean>(true)
    const [allData, setAllData] = useState<{
        shippingEmissionsTimeseriesData?: {
            wtwBreakdownTimeseries: WtwBreakdownTimeseries
            emissionTimeseries: ShippingEmissionTimeseries
        }
        emissionCalculationsData?: CountTimeseries
        orderChartData?: BundleData[]
        breakdowns?: AggregatedAnalyticsByProperty
        unSDGNumbers?: SupportedUNSdg
    }>()

    const from = useMemo(() => getStartEndDates(dateRange).start, [dateRange])
    const through = useMemo(() => getStartEndDates(dateRange).end, [dateRange])
    const interval = useMemo(() => getInterval(dateRange), [dateRange])

    const loadShippingEmissionTimeseriesData = useCallback(async () => {
        try {
            return await getShippingEmissionTimeseries({
                from,
                through,
                interval,
                accountIds: selectedAccountIds,
            })
        } catch {
            snackbar(SnackbarMessages.LOAD_ANALYTICS_FAIL)
        }
    }, [from, through, interval, selectedAccountIds, snackbar])

    const loadEmissionCalculations = useCallback(async () => {
        try {
            const result = await getEmissionCalculationMetrics({
                from,
                through,
                accountIds: selectedAccountIds,
                interval,
            })
            return result.timeseries
        } catch {
            snackbar(SnackbarMessages.LOAD_ANALYTICS_FAIL)
        }
    }, [from, through, selectedAccountIds, interval, snackbar])

    const loadOrderChartData = useCallback(async () => {
        try {
            return await getCumulativeAnalyticsPerBundle({
                from,
                through,
                accountIds: selectedAccountIds,
            })
        } catch {
            snackbar(SnackbarMessages.LOAD_ANALYTICS_FAIL)
        }
    }, [from, selectedAccountIds, snackbar, through])

    const loadBreakdowns = useCallback(async () => {
        try {
            return await getAggregatedAnalyticsByProperty({
                from,
                through,
                accountIds: selectedAccountIds,
            })
        } catch {
            snackbar(SnackbarMessages.LOAD_ANALYTICS_FAIL)
        }
    }, [from, selectedAccountIds, snackbar, through])

    const loadUNSDGNumbers = useCallback(async () => {
        try {
            return (await getMetrics({ from, through, accountIds: selectedAccountIds })).unSdg
        } catch {
            snackbar(SnackbarMessages.LOAD_ANALYTICS_FAIL)
        }
    }, [from, selectedAccountIds, snackbar, through])

    useEffect(() => {
        setLoading(true)
        // load all data
        Promise.all([
            loadShippingEmissionTimeseriesData(),
            loadEmissionCalculations(),
            loadOrderChartData(),
            loadBreakdowns(),
            loadUNSDGNumbers(),
        ]).then(
            ([
                shippingEmissionsTimeseriesData,
                emissionCalculationsData,
                orderChartData,
                breakdowns,
                unSDGNumbers,
            ]) => {
                setAllData({
                    shippingEmissionsTimeseriesData,
                    emissionCalculationsData,
                    orderChartData,
                    breakdowns,
                    unSDGNumbers,
                })
                setLoading(false)
            },
        )
    }, [
        loadEmissionCalculations,
        loadOrderChartData,
        loadShippingEmissionTimeseriesData,
        loadBreakdowns,
        loadUNSDGNumbers,
        snackbar,
    ])

    return (
        <Stack
            direction="column"
            sx={{
                width: '100%',
            }}
            spacing={{
                xs: 9,
            }}
        >
            <LoadingWrapper
                sx={{
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: `600px`,
                }}
                loading={loading}
            >
                {allData?.shippingEmissionsTimeseriesData && (
                    <EmissionsSectionChartToggle
                        loading={loading}
                        data={{ ...allData.shippingEmissionsTimeseriesData }}
                        dateRange={dateRange}
                    />
                )}
                <EmissionCalculationAnalytics
                    data={allData?.emissionCalculationsData}
                    dateRange={dateRange}
                />
                <OrderChartsWrappers
                    data={allData?.orderChartData}
                    dateRange={dateRange}
                    breakdowns={allData?.breakdowns}
                    unSDGNumbers={allData?.unSDGNumbers}
                />
            </LoadingWrapper>
        </Stack>
    )
}

const ChartsWrapperMemo = memo(ChartsWrapper, compareParameters)

const Dashboard = () => {
    const today = useMemo(() => moment(), [])
    const mixpanel = useMixpanel()

    // selectedAccountIds === undefined (as opposed to []) --> the logic to parse the query params has not yet run
    const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>()
    const [dateRange, setDateRange] = useState<Range>({
        startDate: today.clone().subtract(12, `months`).toDate(),
        endDate: today.toDate(),
    })

    useEffect(() => {
        mixpanel.track('analytics_page_viewed')
    }, [mixpanel])

    const { recentlyUsedAccounts } = useRecentlyUsedAccounts()

    return (
        <MainLayoutContainer>
            <FiltersWithQueryParamsWrapper
                setDateRange={setDateRange}
                dateRange={dateRange}
                setSelectedAccountIds={setSelectedAccountIds}
                selectedAccountIds={selectedAccountIds}
            />
            {/* selectedAccountIds === undefined (as opposed to []) means the logic to parse the query params is still running
            👇 Thus we don't want to render the charts, yet before we know what accounts to show */}
            {selectedAccountIds !== undefined && (
                <>
                    <Box
                        sx={{
                            alignItems: 'center',
                            padding: '8px 0',
                            background: `white`,
                            position: 'sticky',
                            top: 0,
                            left: 0,
                            zIndex: 99,
                            mb: 4,
                        }}
                    >
                        <FiltersBox
                            preselectedDateRange={dateRange}
                            preselectedAccountIds={selectedAccountIds}
                            preselectedTypes={[]}
                            setSelectedAccountIds={
                                setSelectedAccountIds as Dispatch<SetStateAction<string[]>>
                            }
                            setDateRange={setDateRange}
                            transformDropDownLabel={(label) => EstimateTypeName(label as any)}
                            dropDownFilterName={'type'}
                        />
                    </Box>
                    <LoadingWrapper
                        sx={{
                            display: 'flex',
                            position: 'relative',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: `600px`,
                        }}
                        loading={!recentlyUsedAccounts}
                    >
                        {/*  Wait until recentlyUsedAccounts have been loaded to show the analytics */}
                        {recentlyUsedAccounts && (
                            <ChartsWrapperMemo
                                selectedAccountIds={selectedAccountIds}
                                dateRange={dateRange}
                            />
                        )}
                    </LoadingWrapper>
                </>
            )}
        </MainLayoutContainer>
    )
}

export default Dashboard
